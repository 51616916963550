import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  DotGroup,
} from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"
import HubspotForm from "react-hubspot-form"

const AnnualRewardsRecognitionBenchmarkingReportIndia2024 = ({ location }) => {
  return (
    <Layout location={location}>
      <Helmet>
        <title>
        Annual Rewards and Recognition Benchmarking Report powered by AIRe framework- India (2024-2025)
        </title>

        <meta
          name="description"
          content="Discover key insights from the Annual Rewards and Recognition Benchmarking Report for India by Vantage Circle, SHRM, and AON. Explore quantifiable benchmarks, best practices from 250+ companies, and expert insights to enhance your R&R programs."
        />
        <meta
          property="twitter:title"
          content="Annual Rewards and Recognition Benchmarking Report powered by AIRe framework- India (2024-2025)"
        />

        <meta
          property="twitter:description"
          content="Discover key insights from the Annual Rewards and Recognition Benchmarking Report for India by Vantage Circle, SHRM, and AON. Explore quantifiable benchmarks, best practices from 250+ companies, and expert insights to enhance your R&R programs."
        />

        <meta property="og:type" content="website" />

        <meta
          property="og:url"
          content="https://www.vantagecircle.com/annual-rewards-and-recognition-benchmarking-report-india-2024/"
        />

        <meta property="og:site_name" content="Vantage Circle" />
        <meta
          property="og:title"
          content="Annual Rewards and Recognition Benchmarking Report powered by AIRe framework- India (2024-2025)"
        />

        <meta
          property="og:description"
          content="Discover key insights from the Annual Rewards and Recognition Benchmarking Report for India by Vantage Circle, SHRM, and AON. Explore quantifiable benchmarks, best practices from 250+ companies, and expert insights to enhance your R&R programs."
        />
        <link
          rel="canonical"
          href="https://www.vantagecircle.com/annual-rewards-and-recognition-benchmarking-report-india-2024/"
        ></link>
      </Helmet>

      <section className="bg-gray-newGrayDark mx-auto py-10 md:py-20 mb-0 relative">
        <div className="container max-w-5xl sm:text-center px-6 sm:px-6 lg:px-6">
          <h1 className="homepage-heading text-center">
          Annual Rewards and Recognition Report India: (2024-2025) 
          </h1>
          <p className="section-subtitle text-center">
          With SHRM and Aon as the prestigious knowledge partners this report delves deep into the design and execution of Recognition and Rewards (R&R) programs within the Indian corporate landscape. 
          This research is powered by the AIR<sup>e</sup> framework which leverages the principles of behavioural science to derive the elements of R&R Program design and its effectiveness.  
          </p>
          <div className="mt-5 sm:mt-8 md:mt-10 flex justify-center">
            <div className="grid place-content-center">
              <a href="#download-now" className="vc-colored-btn mx-auto mb-10">
                Download now
              </a>
              <img loading="lazy"  src="https://res.cloudinary.com/vantagecircle/image/upload/v1666853042/gatsbycms/uploads/2024/05/SHRM-AON-logo-AIRe.png" />
            </div>
          </div>
        </div>
      </section>

      {/* <section className="ebook-carousel container text-center max-w-4xl md:-mt-36 mb:mb-20 px-7">
        <CarouselProvider
          className="relative mx-auto"
          naturalSlideWidth={110}
          naturalSlideHeight={80}
          totalSlides={4}
        >
          <Slider className="">
            <Slide index={0}>
              <img
                src="https://res.cloudinary.com/vantagecircle/image/upload/v1666853042/gatsbycms/uploads/2024/05/MicrosoftTeams-image-2.png"
                alt="Slide1"
              />
            </Slide>
            <Slide index={1}>
              <img
                src="https://res.cloudinary.com/vantagecircle/image/upload/v1666853042/gatsbycms/uploads/2024/05/MicrosoftTeams-image-3.png"
                alt="Slide2"
              />
            </Slide>
            <Slide index={2}>
              <img
                src="https://res.cloudinary.com/vantagecircle/image/upload/v1666853042/gatsbycms/uploads/2024/05/MicrosoftTeams-image-4.png"
                alt="Slide3"
              />
            </Slide>
            <Slide index={3}>
              <img
                src="https://res.cloudinary.com/vantagecircle/image/upload/v1666853042/gatsbycms/uploads/2024/05/MicrosoftTeams-image-5.png"
                alt="Slide4"
              />
            </Slide>
          </Slider>
          <ButtonBack className="shadow eb-carousel-btn">
            <svg
              className="mx-auto text-purple-200"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
            </svg>
          </ButtonBack>
          <ButtonNext className="shadow eb-carousel-btn">
            <svg
              className="mx-auto text-purple-200"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z" />
            </svg>
          </ButtonNext>
          <DotGroup />
        </CarouselProvider>
      </section> */}
      <section
        id="download-now"
        className="container max-w-7xl py-10 xl:py-20 mx-auto px-6"
      >
        <div className="">
          <div className="md:flex">
            <div className="card-1 w-full md:w-1/2 mb-10 md:mb-0 lg:mt-0">
              <h2 className="mb-5 sm:mb-10 text-3xl text-gray-900 sm:text-4xl">
                You will get to learn:
              </h2>
              <ul className="form-bullets list-inside orangebullets">
                <li>
                Quantifiable Benchmarks for R&R programs.
                </li>
                <li>
                Best R&R Practices followed by 250+ companies across India.
                </li>
                <li>
                Detailed rewards and recognition program insights from 9 industries across India.
                </li>
                <li>
                10+ Interviews from HR Leaders with their success mantras for R&R Programs.
                </li>
              </ul>
            </div>
            <div className="card-1 w-full md:w-1/2 text-gray-900 rounded-xl shadow-lg p-8 lg:p-10 md:ml-28">
              <div className="form-section">
                <h2 className="mt-2 mb-8 text-2xl text-center text-gray-900 sm:text-3xl">
                  Fill up to download your copy now
                </h2>
                <HubspotForm
                  portalId="6153052"
                  formId="8e8aea83-4b27-404a-b349-088cedd274d6"
                  onSubmit={() => console.log("Submit!")}
                  onReady={form => console.log("Form ready!")}
                  loading={<div>Loading...</div>}
                />
                {/* <div className="notetext text-center font-normal text-sm">We safeguard your personal information in accordance with our <a href="/privacy-policy/" target="_blank">Privacy Policy</a></div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default AnnualRewardsRecognitionBenchmarkingReportIndia2024